import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

import Image from 'components/shared/Image'
import ParseContent from './ParseContent'
import CustomLink from './CustomLink'

const Icon = styled(Image)`
  height: 60px;
`

const ServiceCol = styled.div`
  &:nth-of-type(even) {
   & > div {
      background-color: ${props => props.theme.color.face.contrast};
    }
  }

  &:nth-of-type(odd) {
    & > div {
      background-color: ${props => props.theme.color.face.black};
    }
  }

  &:nth-child(2) {
    & h2:after {
      background-color: ${props => props.theme.color.face.light};
    }
  } 
`

const ServiceColLink = styled(CustomLink)`
  &:nth-of-type(even) {
   & > div {
      background-color: ${props => props.theme.color.face.black};
    }
  }

  &:nth-of-type(odd) {
    & > div {
      background-color: ${props => props.theme.color.face.contrast};
    }
  }

  &:nth-child(2) {
    & h2:after {
      background-color: ${props => props.theme.color.face.light};
    }
  } 

  &:hover {
    text-decoration: none !important;
  }
`



const Link = styled(CustomLink)`
  color: ${props => props.theme.color.text.light};
  font-weight: ${props => props.theme.font.weight['700']};
`

const Content = styled(ParseContent)`
 color: ${props => props.theme.color.text.light};

 & h2 {
   font-size: ${props => props.theme.font.size.l};
   line-height: 34px;
   font-weight: ${props => props.theme.font.weight['700']};
 }

 ${props => props.withStripe && css`
  & h2 {
     margin-bottom: 30px;

     &:after {
      content: '';
      width: 37px;
      height: 3px;
      display: block;
      margin-top: 17px;
      background-color: ${props => props.theme.color.face.contrast};
    }
  }
 `}
  
`

const InnerCol = styled.div``

const Services = ({ ids, customPadding }) => {
  const { service } = useStaticQuery(graphql`
  {
     service: allWordpressWpDiensten(sort: {order: ASC, fields: date}) {
        edges {
          node {
            title
            wpId: wordpress_id
            path
            acf {
              inhoud {
                icon {
                  localFile {
                    publicURL
                  }
                }
                description
              }
            }
          }
        }
      }
  }
`)

let servicesPosts = service.edges 

if(ids) {
  servicesPosts = servicesPosts.filter(({ node: { wpId } }) => ids.indexOf(wpId) !== -1)
}
  return (
    <>
    {servicesPosts.map(({ node }, index) => (
      <>
        {!customPadding ? (
          <ServiceColLink to={node.path} className="col px-0 h-100" key={index}>
            <InnerCol className="p-3 p-lg-5 h-100" >
              <Icon src={node.acf.inhoud.icon.localFile.publicURL} className="mb-4" />
              <Content content={node.acf.inhoud.description} />
            </InnerCol>
        </ServiceColLink>
        ) : (
          <ServiceCol className="col-sm-6 col-md-4 p-0" key={index} id={node.wpId}>
            <InnerCol className="p-3 p-lg-5 h-100" >
              <div className="px-4 pr-lg-0 pl-lg-4 py-3">
                <Icon src={node.acf.inhoud.icon.localFile.publicURL} className="mb-4" />
                <Content content={node.acf.inhoud.description} withStripe />
                <Link to={node.path}>Lees meer</Link>
              </div>
            </InnerCol>
          </ServiceCol>
        )}
      </>
    ))}
    </>
  )
}

export default Services
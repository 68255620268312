import React from 'react'
import styled from 'styled-components'

// Elements
import HeroDefault from 'components/elements/HeroDefault'
import ButtonDefault from 'components/elements/ButtonDefault'

// Shared
import Image from 'components/shared/Image'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import Content from 'components/shared/Content'

import Services from 'components/shared/Services'

const Intro = styled.section`
  background-color: ${props => props.theme.color.face.secondary};
  
  @media (min-width: 992px){
    height: 334px;
  }
`

const AboutImage = styled(Image)`
  width: 540px;
  height: auto;
`

const IntroContent = styled(Content)`
  color: ${props => props.theme.color.text.light};

  & h1 {
    margin-bottom: 2rem;

    @media (max-width: 991px){
      font-size: 28px;
    }

    &::after {
     content: '';
     width: 37px;
     height: 3px;
     display: block;
     margin-top: 27px;
     background-color: ${props => props.theme.color.face.contrast};
   }
  }
`

const AboutContent = styled(Content)`
  & h2 {
    margin-bottom: 2rem;

    &::after {
     content: '';
     width: 37px;
     height: 3px;
     display: block;
     margin-top: 27px;
     background-color: ${props => props.theme.color.face.contrast};
   }
  }
`

const HomeTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <HeroDefault fields={acf.banner} />

      <Intro>
        <div className="row align-items-center px-4 px-lg-0 py-4 py-lg-0 pl-lg-5 h-100">
          <div className="col-lg-4 pr-lg-5">
            <IntroContent content={acf.intro.description} />
          </div>
          <Services ids={acf.intro.services.map((id) => id)}  />
        </div>
      </Intro>

      <section>
        <div className="container py-lg-5">
          <div className="row py-5 align-items-center">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <AboutImage src={acf.aboutUs.image} />
            </div>
            <div className="col-lg-6 pl-lg-5">
              <AboutContent content={acf.aboutUs.description} className="mb-4" />
              <ButtonDefault to="/">Lees meer</ButtonDefault>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="row">
         <Services ids={acf.services.map((id) => id)} customPadding/>
        </div>
      </section>

      <section>
        <div className="container py-3">
          {/* <div className="row py-5">
            <p>Ruimte voor portfolio</p>
          </div> */}
        </div>
      </section>
      
    </Layout>
  )
}

export default HomeTemplate